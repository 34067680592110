const lineItemConstants = {
  hidden: "hidden",
  merchandise: "merchandise",
  additional_services: "additional_services",
  no_item_type: "no_item_type", // DO NOT REMOVED Default
  new: "new_condition",
  used: "used_condition",
  no_condition: "no_condition", // DO NOT REMOVED Default
  tax: "tax",
  damaged_condition: "damaged_condition",
  credit: "credit",
  lease: "lease",
  disabled: "disabled",
  edit: "edit",
  contract_does_not_exist: '"null"',
  user: "user",
  merchant: "merchant",
  regex: /[a-zA-Z0-9]/,
}

export default lineItemConstants
