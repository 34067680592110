function getTaxPercentages(controller) {
  return {
    puertoRicanSalesTaxPercentage: controller.hasPuertoRicanSalesTaxTarget ? Number(controller.puertoRicanSalesTaxTarget.dataset.taxpercentage): 0,
    provincialSalesTaxPercentage: controller.hasProvincialSalesTaxTarget ? Number(controller.provincialSalesTaxTarget.dataset.taxpercentage) : 0,
    goodsAndServicesTaxPercentage: controller.hasGoodsAndServicesTaxTarget ? Number(controller.goodsAndServicesTaxTarget.dataset.taxpercentage) : 0,
    harmonizedSalesTaxPercentage: controller.hasHarmonizedSalesTaxTarget ? Number(controller.harmonizedSalesTaxTarget.dataset.taxpercentage) : 0,
  }
}

export function fillTaxesAmountsOut(controller, taxesAmounts) {
  if (controller.hasHarmonizedSalesTaxTarget) {
    cleanErrors('harmonizedSalesTaxStatus', 'harmonizedSalesTaxInvalid');
    controller.harmonizedSalesTaxTarget.value = taxesAmounts.harmonizedSalesTaxAmount.toFixed(2);
  }

  if (controller.hasProvincialSalesTaxTarget) {
    cleanErrors('provincialSalesTaxStatus', 'provincialSalesTaxInvalid');
    controller.provincialSalesTaxTarget.value = taxesAmounts.provincialSalesTaxAmount.toFixed(2);
  }

  if (controller.hasGoodsAndServicesTaxTarget) {
    cleanErrors('goodsAndServicesTaxStatus', 'goodsAndServicesTaxInvalid');
    controller.goodsAndServicesTaxTarget.value = taxesAmounts.goodsAndServicesTaxAmount.toFixed(2);
  }

  if (controller.hasPuertoRicanSalesTaxTarget) {
    cleanErrors('puertoRicanSalesTaxStatus', 'puertoRicanSalesTaxInvalid');
    controller.puertoRicanSalesTaxTarget.value = taxesAmounts.puertoRicanSalesTaxAmount.toFixed(2);
  }

  fillAfterTaxesAmountsBlock(controller, taxesAmounts)
}

function cleanErrors(statusTargetName, messageTargetName) {
  const inputError = document.querySelector(`div[data-target="foreign-taxes.${statusTargetName}"]`);
  const errorMessage = document.querySelector(`span[data-target="foreign-taxes.${messageTargetName}"]`);

  inputError.classList.remove("has-error");
  inputError.classList.add("has-success");
  errorMessage.classList.add("hidden");
}

function calculateTaxAmounts(taxPercentages, invoiceTotal) {
  return {
    puertoRicanSalesTaxAmount: invoiceTotal * taxPercentages.puertoRicanSalesTaxPercentage,
    provincialSalesTaxAmount: invoiceTotal * taxPercentages.provincialSalesTaxPercentage,
    goodsAndServicesTaxAmount: invoiceTotal * taxPercentages.goodsAndServicesTaxPercentage,
    harmonizedSalesTaxAmount: invoiceTotal * taxPercentages.harmonizedSalesTaxPercentage,
  }
}

function fillAfterTaxesAmountsBlock(controller, taxesAmounts) {
  if (controller.hasNewHarmonizedSalesTaxTarget) {
    controller.newHarmonizedSalesTaxTarget.innerHTML = accounting.formatMoney(taxesAmounts.harmonizedSalesTaxAmount.toFixed(2),"$", 2, ",");
  }

  if (controller.hasNewProvincialSalesTaxTarget) {
    controller.newProvincialSalesTaxTarget.innerHTML = accounting.formatMoney(taxesAmounts.provincialSalesTaxAmount.toFixed(2),"$", 2, ",");
  }

  if (controller.hasNewGoodsAndServicesTaxTarget) {
    controller.newGoodsAndServicesTaxTarget.innerHTML = accounting.formatMoney(taxesAmounts.goodsAndServicesTaxAmount.toFixed(2),"$", 2, ",");
  }

  if (controller.hasNewPuertoRicanSalesTaxTarget) {
    controller.newPuertoRicanSalesTaxTarget.innerHTML = accounting.formatMoney(taxesAmounts.puertoRicanSalesTaxAmount.toFixed(2),"$", 2, ",");
  }
}

function resetAfterTaxesAmountsSection(controller) {
  if (controller.hasNewHarmonizedSalesTaxTarget) {
    controller.newHarmonizedSalesTaxTarget.innerHTML = "-";
  }

  if (controller.hasNewProvincialSalesTaxTarget) {
    controller.newProvincialSalesTaxTarget.innerHTML = "-";
  }

  if (controller.hasNewGoodsAndServicesTaxTarget) {
    controller.newGoodsAndServicesTaxTarget.innerHTML = "-";
  }

  if (controller.hasNewPuertoRicanSalesTaxTarget) {
    controller.newPuertoRicanSalesTaxTarget.innerHTML = "-";
  }
}

function isNotACanadianLease() {
  const country = document.querySelector('#adjust-breakdown-content').dataset.country;

  return !(country === "ca");
}

export function isACanadianLease() {
  return !isNotACanadianLease();
}

function includeTaxAmountInInvoiceTotal(controller) {
  const newInvoiceTotal = calculateNewInvoiceTotal(controller);

  controller.newInvoiceTotalTarget.innerHTML = accounting.formatMoney(
    newInvoiceTotal,
    "$",
    2
  );
}

function isTaxAmountValid(taxAmount) {
  return !(isNaN(taxAmount) || taxAmount < 0);
}

function isTaxAmountInvalid(taxAmount) {
  return !isTaxAmountValid(taxAmount);
}

export function calculateNewInvoiceTotal(controller) {
  let previousInvoiceTotal = 0;

  if (controller.hasMerchantInvoiceTotalTarget) {
    previousInvoiceTotal = Number(controller.merchantInvoiceTotalTarget.value);
  } else {
    previousInvoiceTotal = Number(controller.invoiceTotalTarget.value);
  }

  let taxAmount = 0;

  if (isNaN(previousInvoiceTotal)) {
    return;
  }

  if (isNotACanadianLease()) {
    return previousInvoiceTotal;
  }

  if (controller.hasProvincialSalesTaxTarget) {
    const provincialSalesTax = controller.provincialSalesTaxTarget.value;
    
    if (isTaxAmountValid(provincialSalesTax)) {
      taxAmount += parseFloat(provincialSalesTax);
    }
  }

  return previousInvoiceTotal + taxAmount;
}

export function selectPuertoRicanSalesTax(controller, taxAmount) {
  const newTaxAmount = Number(taxAmount);

  if (isTaxAmountInvalid(newTaxAmount)) {
    controller.newPuertoRicanSalesTaxTarget.innerHTML = "-";
    return;
  }

  controller.newPuertoRicanSalesTaxTarget.innerHTML = accounting.formatMoney(newTaxAmount, "$", 2);
  
  includeTaxAmountInInvoiceTotal(controller);
}

export function selectProvincialSalesTax(controller, taxAmount) {
  const newTaxAmount = Number(taxAmount);

  if (isTaxAmountInvalid(newTaxAmount)) {
    controller.newProvincialSalesTaxTarget.innerHTML = "-";
    return;
  }

  controller.newProvincialSalesTaxTarget.innerHTML = accounting.formatMoney(newTaxAmount, "$", 2);
  
  includeTaxAmountInInvoiceTotal(controller);
}

export function selectGoodsAndServicesTax(controller, taxAmount) {
  const newTaxAmount = Number(taxAmount);

  if (isTaxAmountInvalid(newTaxAmount)) {
    controller.newGoodsAndServicesTaxTarget.innerHTML = "-";
    return;
  }

  controller.newGoodsAndServicesTaxTarget.innerHTML = accounting.formatMoney(
    newTaxAmount,
    "$",
    2
  );

  includeTaxAmountInInvoiceTotal(controller);
}

export function selectHarmonizedSalesTax(controller, taxAmount) {
  const newTaxAmount = Number(taxAmount);

  if (isTaxAmountInvalid(newTaxAmount)) {
    controller.newHarmonizedSalesTaxTarget.innerHTML = "-";
    return;
  }

  controller.newHarmonizedSalesTaxTarget.innerHTML = accounting.formatMoney(
    newTaxAmount,
    "$",
    2
  );

  includeTaxAmountInInvoiceTotal(controller);
}

export function setTaxAmount(controller) {
  let newInvoiceTotal = 0;
  const taxPercentages = getTaxPercentages(controller);

  if (controller.hasMerchantInvoiceTotalTarget) {
    newInvoiceTotal = Number(controller.merchantInvoiceTotalTarget.value);
  } else {
    newInvoiceTotal = Number(controller.invoiceTotalTarget.value);
  }

  if (isNaN(newInvoiceTotal)) {
    return;
  }

  const taxesAmounts = calculateTaxAmounts(taxPercentages, newInvoiceTotal);
  
  fillTaxesAmountsOut(controller, taxesAmounts);
}

export function resetTaxAmount(controller) {
  fillTaxesAmountsOut(controller, {
    puertoRicanSalesTaxAmount: 0,
    provincialSalesTaxAmount: 0,
    goodsAndServicesTaxAmount: 0,
    harmonizedSalesTaxAmount: 0,
  })

  resetAfterTaxesAmountsSection(controller)
}
