import { Controller } from "stimulus";
import {
  showConfirmDialog
} from "../shared/common/dialogs/confirm_dialog_controller";

/** Confirm the merchant delivery */

export default class extends Controller {
  static targets = [
    "calendar",
    "merchantReferenceNumber",
    "merchantReferenceNumberStatus",
    "submitMerchantReference",
    "merchantSalesRepId",
    "merchantSalesRepIdStatus",
    "submitMerchantSalesRepId",
    "submitBtn"
  ];

  /** Only allow delivery submission when the delivery date is present */
  changeDelivery() {
    if (this.calendarTarget.value == "") {
      this.submitBtnTarget.disabled = true;
    } else {
      this.submitBtnTarget.disabled = false;
    }
  }

  /**
   * Sets up and shows the dialog
   */
  showDialog() {
    this.validateMerchantReferenceNumber();
    this.validateMerchantSalesRepId();

    if (this.validMerchantReferenceNumber && this.validMerchantSalesRepId) {
      showConfirmDialog({
        title: this.dialogTitle,
        message: this.dialogMessage,
        cancelText: this.data.get("goBack"),
        confirmText: this.data.get("continue"),
        action: this.confirmDelivery
      });
    }
  }

  showMerchantReferenceDialog() {
    this.validateMerchantReferenceNumber();

    if (this.validMerchantReferenceNumber) {
      showConfirmDialog({
        cancelText: this.data.get("goBack"),
        confirmText: this.data.get("continue"),
        action: this.saveMerchantReference
      });
    }
  }

  showMerchantSalesRepIdDialog() {
    this.validateMerchantSalesRepId();

    if (this.validMerchantSalesRepId) {
      showConfirmDialog({
        cancelText: this.data.get("goBack"),
        confirmText: this.data.get("continue"),
        action: this.saveMerchantSalesRepId
      });
    }
  }

  saveMerchantReference = () => {
    this.confirmDelivery("merchantReferenceNumber");
  }

  saveMerchantSalesRepId = () => {
    this.confirmDelivery("merchantSalesRepId");
  }

  get merchantReferenceParams() {
    return JSON.stringify({
      delivery_confirmation: {
        contract_id: this.data.get("contractId"),
        merchant_reference_number: this.merchantReferenceNumberTarget.value
      }
    });
  }

  get merchantSalesRepIdParams() {
    return JSON.stringify({
      delivery_confirmation: {
        contract_id: this.data.get("contractId"),
        merchant_sales_rep_id: this.merchantSalesRepIdTarget.value
      }
    });
  }

  /**
   * Submit the delivery confirmation form
   */

  confirmDelivery = (merchantAttribute = null) => {
    var payLoad;
    switch (merchantAttribute) {
    case "merchantReferenceNumber":
      payLoad = this.merchantReferenceParams;
      break;
    case "merchantSalesRepId":
      payLoad = this.merchantSalesRepIdParams;
      break;
    default:
      payLoad = this.formData;
      break;
    }

    const fetchWith = window.acima.fetchInit({
      method: this.fetchMethod,
      body: payLoad
    });

    const url = this.data.get("url");
    const progressUrl = this.data.get("progressUrl");
    this.disableTargetBtn(merchantAttribute);
    trackContractStep();
    fetch(url, fetchWith).then(response => {
      response.json();

      if(response.ok){
        this.sendTrackingEventToGTM()
      }
    }).then(response => {
      window.location.href = progressUrl;
    });
  }

  sendTrackingEventToGTM = () => {
    const sha256EmailAddress = this.data.get("applicantEmailData")
    const sha256PhoneNumber = this.data.get("applicantPhoneData")

    if(sha256EmailAddress !== null && sha256PhoneNumber !== null){
      const userData = {
        userData: {
          sha256_email_address: sha256EmailAddress,
          sha256_phone_number: sha256PhoneNumber,
        }
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "fundedLeaseJS",
        userData: userData,
        send_to: "AW-833393615/5xa7CLyk4KwBEM-nso0D",
      });
    }
  }

  disableTargetBtn = merchantAttribute => {
    switch (merchantAttribute) {
    case "merchantReferenceNumber":
      if (this.hasSubmitMerchantSalesRepIdTarget) {
        this.submitMerchantSalesRepIdTarget.disabled = true;
      }
      this.submitMerchantReferenceTarget.disabled = true;
      break;
    case "merchantSalesRepId":
      if (this.hasSubmitMerchantReferenceTarget) {
        this.submitMerchantReferenceTarget.disabled = true;
      }
      this.submitBtnTarget.disabled = true;
      break;
    default:
      if (this.hasSubmitMerchantReferenceTarget && this.hasSubmitMerchantSalesRepIdTarget) {
        this.submitMerchantReferenceTarget.disabled = true;
        this.submitMerchantSalesRepIdTarget.disabled = true;
      }
    }
  }

  /**
   * Validate merchant reference number field
  */
  validateMerchantReferenceNumber() {
    if (this.hasMerchantReferenceNumberStatusTarget) {
      if (this.validMerchantReferenceNumber) {
        this.addSuccess(this.merchantReferenceNumberStatusTarget);
      } else {
        this.addError(this.merchantReferenceNumberStatusTarget);
      }
    }
  }

  /**
   * Validate merchant sales rep id
  */
  validateMerchantSalesRepId() {
    if (this.hasMerchantSalesRepIdStatusTarget) {
      if (this.validMerchantSalesRepId) {
        this.addSuccess(this.merchantSalesRepIdStatusTarget);
      } else {
        this.addError(this.merchantSalesRepIdStatusTarget);
      }
    }
  }

  /**
    * Returns the dialog title
    */
  get dialogTitle() {
    if (Date.parse(this.selectedDeliveryDate) > Date.today()) {
      return this.data.get("deliveredFutureTitle");
    } else {
      return this.data.get("deliveredTitle");
    }
  }

  /**
   * Returns the dialog message
   */
  get dialogMessage() {
    if (Date.parse(this.selectedDeliveryDate) > Date.today()) {
      return this.data.get("deliveredFutureMessage") + " " +
        this.selectedDeliveryDate;
    } else {
      return this.data.get("deliveredMessage");
    }
  }

  /**
   * Returns method to post to controller
   * This is used to determine which workflow the server uses.
   * IF confirmationExists then CREATE a new record ELSE just update existing.
   * @return {string}
   */
  get fetchMethod() {
    const dataMap = { true: "PUT", false: "POST" };
    const exists = this.data.get("confirmationExists");
    return dataMap[exists];
  }

  /**
   * Returns JSON string of params
   * @return {string}
   */
  get formData() {
    var pendingFormData = {
      delivery_confirmation: {
        contract_id: this.data.get("contractId"),
        selected_delivery_date: this.selectedDeliveryDate
      }
    };
    if (this.hasMerchantReferenceNumberTarget) {
      pendingFormData["delivery_confirmation"]["merchant_reference_number"] =
        this.merchantReferenceNumberTarget.value;
    }

    if (this.hasMerchantSalesRepIdTarget) {
      pendingFormData["delivery_confirmation"]["merchant_sales_rep_id"] =
        this.merchantSalesRepIdTarget.value;
    }
    return JSON.stringify(pendingFormData).toString();
  }

  /**
   * Returns the selected delivery date string
   * @return {string}
   */
  get selectedDeliveryDate() {
    return this.calendarTarget.value;
  }

  /**
   * Checks if the merchant reference number is valid
   * @return {boolean}
   */
  get validMerchantReferenceNumber() {
    // if merchant reference number status is not present, skipping validation.
    if (!this.hasMerchantReferenceNumberStatusTarget) {
      return true;
    } else {
      return this.merchantReferenceNumberReady &&
        this.hasMerchantReferenceNumberTarget;
    }
  }

  /**
   * Checks if the merchant reference number is present
   * @return {boolean}
   */
  get merchantReferenceNumberReady() {
    if (this.hasMerchantReferenceNumberTarget) {
      return this.merchantReferenceNumberTarget.value.trim().length > 0;
    } else {
      return true;
    }
  }

  /**
   * Checks if the merchant sales rep id is valid
   * @return {boolean}
   */
  get validMerchantSalesRepId() {
    // if merchant sales rep id status is not present, skipping validation.
    if (!this.hasMerchantSalesRepIdStatusTarget) return true;

    return this.merchantSalesRepIdReady && this.hasMerchantSalesRepIdTarget;
  }

  /**
   * Checks if the merchant sales rep id is present
   * @return {boolean}
   */
  get merchantSalesRepIdReady() {
    if (this.hasMerchantSalesRepIdTarget) {
      return this.merchantSalesRepIdTarget.value.trim().length > 0;
    }

    return true;
  }

  /**
   * Adds has-success class around text field (green)
   */

  addSuccess(target) {
    target.classList.remove("has-error");
    target.classList.add("has-success");
  }

  /**
   * Adds has-success class around text field (red)
   */

  addError(target) {
    target.classList.remove("has-success");
    target.classList.add("has-error");
  }
}
