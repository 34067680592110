import { Controller } from "stimulus";
import currency from "currency.js";

/** Remittance Report Datatable */
export default class extends Controller {
  static targets = [
    "table"
  ];

  initDataTable() {
    const h = window.acima.escapeHtml;
    const lang = document.documentElement.lang.substring(0, 2);
    const translationUrl = new URL(window.location.origin + `/locale/datatable/${lang}.json`);

    $(this.tableTarget).dataTable({
    "oLanguage": {
      sUrl: translationUrl.toString()
    },
    "bFilter": false,
    "sAjaxSource": "/merchants/reports/remittances",
    "bServerSide": true,
    "bPaginate": true,
    "bInfo": false,
    "iDisplayLength": 20,
    "aaSorting": [[4, "desc"]],
    "fnServerData": function (sSource, aoData, fnCallback) {
        $.ajax({
        "dataType": 'json',
        "type": "GET",
        "url": sSource,
        "data": aoData,
        "dataFilter": function(data){
            const json = $.parseJSON(data);
            json.iTotalRecords = json.total_records;
            json.iTotalDisplayRecords = json.total_records;
            json.aaData = json.payments;
            return JSON.stringify(json);
        },
        "success": function(rs) {
            if (rs.success === false) {
            show_common_error_dialog(rs.data.message || settings.searchServerErrorMessage);
            } else {
            fnCallback(rs);
            }
        },
        "error": function() {
            show_common_error_dialog(settings.searchServerErrorMessage);
        },
        });
    },
    "aoColumns" : [
        { "mDataProp": "guid", "mRender": function ( data, type, full ) {
            return `<a href="/merchants/reports/remittances/${h(data)}?lang=${lang}.leases">${h(full.payment_reference)}</a>`;
        }},
        { "mDataProp": "oracle_reference_id" },
        { "mDataProp": "currency" },
        { "mDataProp": "payment_total", "mRender": function ( data, type, full ) {
            return currency(data).format();
        }},
        { "mDataProp": "remittance_date_time" },
    ],
    "aLengthMenu": [[20],[20]],
    "aoColumnDefs": [
      { "sClass": "guid-td", "aTargets": [0] }
    ]
    });
    $(this.tableTarget).on("init", function() {
      $(this).addClass("overflow-x-auto");
    });
  }

  connect() {
    this.initDataTable();
  }
}
