import { Controller } from "stimulus";
import { publish, subscribe } from "../../../helpers/pub_sub";
import { showConfirmationDialog } from "./confirmation_dialog_controller";
import { validForeignTaxes } from "../../../helpers/foreign_taxes_helper";
import {
  resetTaxAmount,
  setTaxAmount,
  selectGoodsAndServicesTax,
  selectHarmonizedSalesTax,
  selectProvincialSalesTax,
  selectPuertoRicanSalesTax,
  calculateNewInvoiceTotal,
  isACanadianLease,
} from "../../../helpers/foreign_taxes/adjustments/calculator_helper"

const SUBMIT_FORM_EVENT = "submitFormEvent";

/**
 * Publishes the submit form event
 */
export function submitForm() {
  publish(SUBMIT_FORM_EVENT);
}
export default class extends Controller {
  static targets = [
    "invalidInvoiceTotalWarning",
    "calculationWarning",
    "adjustmentForm",
    "newDescription",
    "newDescriptionInput",
    "newInvoiceTotal",
    "newRenewalAmount",
    "merchantInvoiceTotal",
    "conditionNewButton",
    "conditionNewRadio",
    "conditionUsedButton",
    "conditionUsedRadio",
    "damagedButton",
    "damagedCheckBox",
    "damagesDescription",
    "damagesDescriptionInput",
    "goodsAndServicesTax",
    "harmonizedSalesTax",
    "provincialSalesTax",
    "puertoRicanSalesTax",
    "invoiceChangeAmount",
    "downsy",
    "submitButton",
    "newGoodsAndServicesTax",
    "newHarmonizedSalesTax",
    "newProvincialSalesTax",
    "newPuertoRicanSalesTax",
  ]

  connect() {
    this.newConditionChanges();
    this.removeDamagedCheck();
    subscribe(SUBMIT_FORM_EVENT, this.submitForm);
  }

  populateNewDescription() {
    this.newDescriptionTarget.innerHTML = this.newDescriptionInputTarget.value;
  }

  selectNewCondition(event) {
    event.preventDefault();

    this.newConditionChanges();
  }

  newConditionChanges() {
    this.conditionNewButtonTarget.classList.add("btn-selected");
    this.conditionUsedButtonTarget.classList.remove("btn-selected");
    this.conditionNewRadioTarget.checked = true;
  }

  selectUsedCondition(event) {
    event.preventDefault();

    this.conditionNewButtonTarget.classList.remove("btn-selected");
    this.conditionUsedButtonTarget.classList.add("btn-selected");
    this.conditionUsedRadioTarget.checked = true;
  }

  toggleDamagesDescription(event) {
    event.preventDefault();

    if (this.damagedCheckBoxTarget.checked == false) {
      this.damagedCheckBoxTarget.checked = true;
      this.damagedButtonTarget.classList.add("btn-selected");
      this.damagesDescriptionTarget.classList.remove("hidden");
      this.damagesDescriptionInputTarget.value = null;
    } else {
      this.removeDamagedCheck();
    }
  }

  removeDamagedCheck() {
    this.damagedCheckBoxTarget.checked = false;
    this.damagedButtonTarget.classList.remove("btn-selected");
    this.damagedButtonTarget.blur();
    this.damagesDescriptionTarget.classList.add("hidden");
  }

  checkSubmittability() {
    if (this.submittable()) {
      this.submitButtonTarget.classList.remove("disabled");
    } else {
      this.submitButtonTarget.classList.add("disabled");
    }
  }

  submittable() {
    return this.isValidTotalInput() &&
      this.newDescriptionInputTarget.value != null &&
      this.newDescriptionInputTarget.value != "" &&
      validForeignTaxes(this);
  }

  isValidTotalInput() {
    const total = this.merchantInvoiceTotalTarget.value;

    return !(isNaN(total) || (total < 100));
  }

  handleMerchantInvoiceTotal(event) {
    this.clearBreakdownValues();
    this.checkSubmittability();

    if (this.isValidTotalInput() === false) {
      this.addInvalidInvoiceTotalWarning();
      resetTaxAmount(this);
      return;
    }

    var controller = this;
    var params = new URLSearchParams(
      {
        merchant_invoice_total: this.merchantInvoiceTotalTarget.value
      }
    );

    this.getValidations(params, controller, event);
  }

  getValidations(params, controller, event) {
    var fetchWith = window.acima.fetchInit({ method: "GET" });

    fetch(
      `${ controller.data.get("validationsUrl") }?${ params }`,
      fetchWith
    )
      .then(response => response.json())
      .then(function(jsonResponse) {
        controller.handleValidationsResponse(jsonResponse, event);
      }).catch(function(error) {
        console.error(error.message);
      });
  }

  handleValidationsResponse(jsonResponse, event) {
    if (jsonResponse.eligible == false) {
      this.addInvalidInvoiceTotalWarning();
      this.submitButtonTarget.classList.add("disabled");
      this.merchantInvoiceTotalTarget.value = "";
      resetTaxAmount(this);
    } else {
      this.removeInvalidInvoiceTotalWarning();
      this.acquireRenewalPaymentAmount(event);
      this.checkSubmittability();
    }
  }

  acquireRenewalPaymentAmount(event) {
    this.clearBreakdownValues();

    if (event.target == this.merchantInvoiceTotalTarget) {
      setTaxAmount(this);
    }

    var controller = this;
    var invoiceTotalValue = calculateNewInvoiceTotal(controller);

    var params = new URLSearchParams(
      {
        merchant_invoice_total: invoiceTotalValue
      }
    );
    var fetchWith = window.acima.fetchInit({ method: "GET" });

    fetch(
      `${ controller.data.get("calculateAmountsUrl") }?${ params }`,
      fetchWith
    )
      .then(response => response.json())
      .then(function(response) {
        if (response.success === true) {
          controller.removeCalculationWarning();
          controller.newInvoiceTotalTarget.innerHTML =
            accounting.formatMoney(
              invoiceTotalValue, "$", 2, ","
            );
          controller.newRenewalAmountTarget.innerHTML =
            accounting.formatMoney(
              response.renewal_payment_amount, "$", 2, ","
            );
          controller.displayDifference(response, controller);
        } else {
          controller.addCalculationWarning();
          controller.submitButtonTarget.classList.add("disabled");
        }
      }).catch(function(error) {
        controller.newInvoiceTotalTarget.innerHTML =
          accounting.formatMoney(
            invoiceTotalValue, "$", 2, ","
          );
        console.error(error.message);
      });
  }

  displayDifference(response, controller) {
    var newTotal = parseFloat(controller.merchantInvoiceTotalTarget.value);
    var originalTotal = controller.data.get("originalInvoiceTotal");

    if (isACanadianLease() && this.hasNewProvincialSalesTaxTarget) {
      newTotal += parseFloat(this.newProvincialSalesTaxTarget.innerHTML.replace('$', ''));
    }

    var difference = parseFloat(newTotal).toFixed(2)
      - parseFloat(originalTotal);

    controller.downsyTarget.classList.remove("hidden");

    controller.invoiceChangeAmountTarget.innerHTML =
      accounting.formatMoney(Math.abs(difference), "$", 2, ",");
  }

  clearBreakdownValues() {
    this.downsyTarget.classList.add("hidden");
    this.invoiceChangeAmountTarget.innerHTML = "-";
    this.newInvoiceTotalTarget.innerHTML = "-";
    this.newRenewalAmountTarget.innerHTML = "-";
  }

  addInvalidInvoiceTotalWarning() {
    this.invalidInvoiceTotalWarningTarget.classList.remove("hide");
    this.merchantInvoiceTotalTarget.parentElement.classList.add("has-error");
  }

  removeInvalidInvoiceTotalWarning() {
    this.invalidInvoiceTotalWarningTarget.classList.add("hide");
    this.merchantInvoiceTotalTarget.parentElement.classList.remove("has-error");
  }

  addCalculationWarning() {
    this.calculationWarningTarget.classList.remove("hide");
    this.merchantInvoiceTotalTarget.parentElement.classList.add("has-error");
  }

  removeCalculationWarning() {
    this.calculationWarningTarget.classList.add("hide");
    this.merchantInvoiceTotalTarget.parentElement.classList.remove("has-error");
  }

  confirmAdjustment() {
    var changeAmount = this.invoiceChangeAmountTarget.innerHTML;
    var newInvoiceTotal = this.newInvoiceTotalTarget.innerHTML;
    var newDescription = this.newDescriptionTarget.innerHTML;

    showConfirmationDialog(
      changeAmount, newInvoiceTotal, newDescription
    );
  }

  validatePstValue() {
    if (this.hasProvincialSalesTaxTarget) {
      const pstValue = this.provincialSalesTaxTarget.value;
      return pstValue !== "" && pstValue >= 0;
    }
    return false;
  }

  submitForm = () => {
    this.adjustmentFormTarget.submit();
  }

  selectPuertoRicanSalesTaxHandler(event) {
    selectPuertoRicanSalesTax(this, event.target.value);
  }
  
  selectProvincialSalesTaxHandler(event) {
    selectProvincialSalesTax(this, event.target.value);
  }
  
  selectGoodsAndServicesTaxHandler(event) {
    selectGoodsAndServicesTax(this, event.target.value);
  }
  
  selectHarmonizedSalesTaxHandler(event) {
    selectHarmonizedSalesTax(this, event.target.value);
  }
}
