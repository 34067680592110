import { Controller } from "stimulus";

const anyDigitRegex = /^\d+(\.\d+)?$/;
const maximumAmountFactor = 0.4;
const minimumAmountFactor = 0.1;
const prepaidRentValue = "prepaid_rent";
const optionalPaymentElectionValue = "optional_payment_election";
const emptyValues = [null, ""];

/**
 * Handle additional payments on users/contracts (Lease Details tab)
 */
export default class extends Controller {
  static targets = [
    "additionalPaymentsForm",
    "modalTitle",
    "maxPrepaidRentAmount",
    "modalText",
    "prepaidRentTable",
    "optionalPaymentElectionTable",
    "table",
    "amountLabel",
    "minOptionalPaymentAmount",
    "maxOptionalPaymentAmount",
    "amount",
    "amountStatus",
    "selectedPaymentType",
    "amountInvalid",
    "confirmBtn",
    "selectedPaymentTypeLabel",
    "editLinkLabel",
    "editBox",
    "buttonsBox",
    "opeEnabled",
    "selectedRecurringPaymentAmount",
    "OPESummaryBox",
    "renewalPaymentAmount",
    "proratedOptionalPaymentAmount",
    "effectiveRenewalPaymentAmount",
  ];

  /** Opens modal and populate its content */
  openModal(e) {
    this.additionalPaymentsFormTarget.classList.remove("hidden");
    this.populateDynamicContentOnModal(e);
    this.toggleStateConfirmButton();
  }

  /** Closes modal */
  closeModal() {
    const forceMerchandiseTotalValidationWithOPE = this.hasOpeEnabledTarget;
    this.additionalPaymentsFormTarget.classList.add("hidden");
    this.clearAmountStatus();
    this.clearFieldValues();
    if (this.invoicesController.requireOptionalPaymentConfirmation) {
      this.invoicesController.invalidOptionalPaymentLabelTarget.classList.remove("hidden");
    }

    this.invoicesController.setsCurrentOpeAction("close");
    this.invoicesController.validateMerchandiseTotal(forceMerchandiseTotalValidationWithOPE);

    this.invoiceLeaseTermsController.validateAndCalculate();
    this.invoiceLeaseTermsController.updateInvoiceValidation();
  }

  /** Makes sure to clear form field values if the optional payment modal get closed without confirming the amount
   *  or without selecting any option previously, this means by clicking either the X or Cancel buttons. */
  clearFieldValues(){
    if (emptyValues.includes(this.editLinkLabelTarget.getAttribute("data-option-selected"))){
      this.amountTarget.value = "";
      this.selectedPaymentTypeTarget.value = "";
    }
  }

  /** Populates dynamic content on the modal based on user's selection */
  populateDynamicContentOnModal(e){
    const currentTarget = e.target.closest("label");
    const isPrepaidRentSelected = currentTarget.getAttribute("data-option-selected") == prepaidRentValue;
    this.populateModalTitle(currentTarget);
    this.hideTablesContent();
    this.clearAmountValue(isPrepaidRentSelected);

    if (isPrepaidRentSelected){
      this.renderPrepaidRentModalContent();
      this.selectedPaymentTypeTarget.value = prepaidRentValue;
    } else {
      this.selectedPaymentTypeTarget.value = optionalPaymentElectionValue;
      this.renderOptionalPaymentElectionModalContent();
    }
  }

  /** Renders dynamic content on the Prepaid Rent modal */
  renderPrepaidRentModalContent(){
    this.amountLabelTarget.textContent = window.acima.i18n.t("invoices.edit.additional_amount_label");
    this.populatePrepaidRentModalText();
    this.displayAndPopulatePrepaidRentModalTable();
    this.OPESummaryBoxTarget.classList.add("hidden");
    this.amountLabelTarget.classList.remove("hidden");
    this.amountStatusTarget.classList.remove("hidden");
    this.editLinkLabelTarget.classList.remove("hidden");
  }

  /** Renders dynamic content on the Optional Payment Election modal */
  renderOptionalPaymentElectionModalContent(){
    this.amountTarget.value = this.calculatedOpeAmount;
    this.amountTargets.forEach((amount) => {
      amount.textContent = accounting.formatMoney(this.calculatedOpeAmount, "$", 2);
    })
    this.modalTextTarget.innerHTML = window.acima.i18n.t("invoices.edit.optional_payment_election_modal_text");
    this.amountLabelTarget.classList.add("hidden");
    this.amountStatusTarget.classList.add("hidden");
    this.OPESummaryBoxTarget.classList.remove("hidden");
    this.editLinkLabelTarget.classList.remove("hidden");
    this.fetchOpeSummaryAmounts();
  }

  /** Fetches amount for the unsaved invoice and populates the ope summary breakdown */
  fetchOpeSummaryAmounts(e) {
    const leaseId = this.invoiceLeaseTermsController.data.get("leaseId")
    const params = this.buildOpeSummaryParams;

    this.invoiceLeaseTermsController.fetchAmounts(leaseId, params)
    .then(function(data) {
      if (data.success) {
        const amounts = this.filterAmounts(data);
        this.renewalPaymentAmountTarget.textContent = accounting.formatMoney(amounts.renewal_payment_amount, "$", 2)
        this.proratedOptionalPaymentAmountTarget.textContent = accounting.formatMoney(amounts.pro_rate_payment_amount, "$", 2);
        this.effectiveRenewalPaymentAmountTarget.textContent = accounting.formatMoney(amounts.effective_payment_amount, "$", 2);
      }
    }.bind(this));
  }

  /** Filters data based on selected term, defaulted to 1 year term if none is selected */
  filterAmounts(data) {
    if (this.selectedTerm === undefined) {
      return data.details[0];
    }

    const programId = parseInt(this.selectedTerm.dataset.id);
    return data.details.filter((amounts) => amounts.underwriting_program_id == programId)[0];
  }

  /** Populates modal title based on user's selection */
  populateModalTitle(target){
    const modalTitle = target.getAttribute("data-modal-title");
    this.modalTitleTarget.textContent = modalTitle;
  }

  /** Populates renewal payment amount data which is the selected recurring payment amount */
  populatePrepaidRentModalText(){
    const selectedTerm = this.invoicesController.selectedTerm;
    const content = window.acima.i18n.t("invoices.edit.prepaid_rent_modal_text", { amount: `${accounting.formatMoney(selectedTerm.dataset.renewalPaymentAmount, "$", 2)}` });
    this.modalTextTarget.innerHTML = content;
  }

  /** Populates max amount data */
  displayAndPopulatePrepaidRentModalTable(){
    this.prepaidRentTableTarget.classList.remove("hidden");
    this.maxPrepaidRentAmountTarget.textContent = accounting.formatMoney(this.maxPrepaidRentAmount, "$", 2)
  }

  /** Hides tables content within modals */
  hideTablesContent(){
    this.tableTargets.forEach( (target) => {
      target.classList.add("hidden");
    });
  }

  /** Clears amount field if conditions are met */
  clearAmountValue(isPrepaidRentSelected){
    if (isPrepaidRentSelected){
      if (this.selectedPaymentTypeTarget.value == optionalPaymentElectionValue) this.amountTarget.value = "";
    } else {
      if (this.selectedPaymentTypeTarget.value == prepaidRentValue) this.amountTarget.value = "";
    }
  }

  /** Clears displayed feedback on the page */
  clearAmountStatus() {
    this.amountStatusTarget.classList.remove("has-success");
    this.amountStatusTarget.classList.remove("has-error");
    this.amountInvalidTarget.classList.add("hidden");
  }

  /** Runs general validations for amount field and then runs specific based on user's selection */
  validateAmount(e){
    if (this.amountReady) {
      this.isSelectedPaymentTypePrepaidRent ? this.validatePrepaidRentAmount(e) : this.validateOptionalPaymentElectionAmount(e);
    } else {
      const message = window.acima.i18n.t("invoices.validations.only_digits");
      this.addError(message);
      e.stopImmediatePropagation();
    }
  }

  /** Runs specific validations for Prepaid Rent amount */
  validatePrepaidRentAmount(e){
    const min = 1;
    const max = this.maxPrepaidRentAmount;
    this.validateValueInRange(e, min, max);
  }

  /** Validates a value is within a range and displays error message if any */
  validateValueInRange(e, min, max) {
    const value = this.amountTarget.value;

    if (value < min || value > max) {
      const message = window.acima.i18n.t("invoices.validations.amount_within_range", { min: `${accounting.toFixed(min, 2)}`, max: `${accounting.toFixed(max, 2)}` });
      this.addError(message);
      e.stopImmediatePropagation();
    } else {
      this.addSuccess();
    }
  }

  /** Runs specific validations for Optional Payment Election amount */
  validateOptionalPaymentElectionAmount(e){
    const min = this.minOptionalPaymentAmount;
    const max = this.maxOptionalPaymentAmount;
    this.validateValueInRange(e, min, max);
  }

  /** Swaps Additional Payments section and populates content based on user's confirmation */
  swapAdditionalPaymentsSectionState() {
    const editBox = this.editBoxTarget;
    const buttonsBox = this.buttonsBoxTarget;

    editBox.classList.remove("hidden");
    buttonsBox.classList.add("hidden");

    const selectedOption = this.isSelectedPaymentTypePrepaidRent ? prepaidRentValue : optionalPaymentElectionValue;
    this.editLinkLabelTarget.setAttribute("data-option-selected", selectedOption);

    this.editLinkLabelTarget.setAttribute("data-modal-title", window.acima.i18n.t(`invoices.edit.${this.selectedPaymentTypeTarget.value}_modal_title`));
    this.selectedPaymentTypeLabelTarget.textContent = window.acima.i18n.t(`invoices.edit.selected_${this.selectedPaymentTypeTarget.value}_label`, { amount: `${accounting.formatMoney(this.amountTarget.value, "$", 2)}` });
  }

  /** Hides error message if any and green highlight amount field */
  addSuccess(message = "") {
    this.amountStatusTarget.classList.remove("has-error");
    this.amountStatusTarget.classList.add("has-success");

    this.amountInvalidTarget.textContent = message;
    this.amountInvalidTarget.classList.add("hidden");
    this.toggleStateConfirmButton();

    this.invoicesController.setsCurrentOpeAction(null);
  }

  /** Displays error message if any and red highlight amount field */
  addError(message) {
    this.amountStatusTarget.classList.remove("has-success");
    this.amountStatusTarget.classList.add("has-error");

    this.amountInvalidTarget.textContent = message;
    this.amountInvalidTarget.classList.remove("hidden");
    this.toggleStateConfirmButton();
  }

  /** Enables/Disables confirm button if modal amount is valid */
  toggleStateConfirmButton(){
    const isInvalid = this.amountStatusTarget.classList.contains("has-error") || this.amountTarget.value === "";
    const confirmButton = this.confirmBtnTarget;

    confirmButton.disabled = isInvalid;
  }

  /**
   * Allows to opt-out selected payment type
   * Validates retailer invoice total to check whether is still valid or not
   * Runs validations to enable/disabled submit btn
  */
  optOut() {
    const editBox = this.editBoxTarget;
    const buttonsBox = this.buttonsBoxTarget;
    const forceMerchandiseTotalValidationWithOPE = this.hasOpeEnabledTarget;

    buttonsBox.classList.remove("hidden");
    editBox.classList.add("hidden");

    this.editLinkLabelTarget.setAttribute("data-option-selected", "");
    this.amountTarget.value = "";
    this.selectedPaymentTypeTarget.value = "";

    this.invoicesController.invalidOptionalPaymentLabelTarget.classList.add("hidden");
    this.invoicesController.setsCurrentOpeAction("optOut");
    this.invoicesController.validateMerchandiseTotal(forceMerchandiseTotalValidationWithOPE);

    this.invoiceLeaseTermsController.validateAndCalculate();
    this.invoiceLeaseTermsController.updateInvoiceValidation();
  }

  /**
   * Updates Retailer Invoice total value with the current approval amount + the OPE amount
   * Runs validations to enable/disabled submit btn
   * Hides Retailer Invoice total error if displayed
  */
  updateRetailerInvoiceTotal() {
    if (this.isSelectedPaymentTypePrepaidRent) return;

    this.invoiceLeaseTermsController.merchandiseTotalInputTarget.value = accounting.toFixed(this.retailerInvoiceTotal, 2);
    this.invoiceLeaseTermsController.validateAndCalculate();
    this.invoiceLeaseTermsController.updateInvoiceValidation();
    this.invoicesController.merchandiseTotalInputErrorTarget.textContent = "";
    this.invoicesController.setTaxAmountHandler();
  }

  /**
   * Returns InvoicesController reference
   * @return {Object} InvoicesController reference.
  */
  get invoicesController() {
    const selector = document.getElementById("inv_form");
    const controllerName = "users--contracts--invoices";

    return this.application.getControllerForElementAndIdentifier(selector, controllerName);
  }

  /**
   * Returns lease-terms-controller reference
   * @return {Object} lease-terms-controller reference.
  */
    get invoiceLeaseTermsController() {
      const selector = document.querySelector("[data-controller='users--contracts--invoice-lease-terms']");
      const controllerName = "users--contracts--invoice-lease-terms";

      return this.application.getControllerForElementAndIdentifier(selector, controllerName);
    }

  /**
   * Returns whether amount is valid or not (valid means is not empty and is a digit)
   * @return {Boolean} true/false
  */
  get amountReady() {
    return this.amountTarget.value.trim().length > 0 && anyDigitRegex.test(this.amountTarget.value);
  }

  /**
   * Returns whether selected payment type is prepaid rent or not
   * @return {Boolean} true/false
  */
  get isSelectedPaymentTypePrepaidRent() {
    return this.selectedPaymentTypeTarget.value == prepaidRentValue;
  }

  /**
   * Returns Max Prepaid rent amount which is 40% of the invoice total entered
   * @return {Float} max amount value
  */
  get maxPrepaidRentAmount(){
    return parseFloat(this.invoicesController.merchandiseTotalInputTarget.value) * maximumAmountFactor;
  }

  get retailerInvoiceTotal() {
    return parseFloat(this.currentApprovalAmount) + parseFloat(this.calculatedOpeAmount);
  }

  /**
   * Returns calculated optional payment election amount
   * @return {Float}
  */
  get calculatedOpeAmount() {
    return this.invoicesController.suggestedOpeAmount;
  }

  get buildOpeSummaryParams() {
    return new URLSearchParams({
      merchandise_total: this.retailerInvoiceTotal,
      underwriting_program_ids: this.invoiceLeaseTermsController.programIdsToSend,
      optional_payment_amount: this.calculatedOpeAmount,
      selected_payment_type: this.selectedPaymentTypeTarget.value,
    }).toString();
  }

  /**
   * Returns current approval amount
   * @return {Float}
  */
  get currentApprovalAmount(){
    return parseFloat(this.element.getAttribute("data-lease-approved-amount"));
  }

  /**
   * Returns 10% of current approval amount
   * @return {Float}
  */
  get minOptionalPaymentAmount(){
    return parseFloat(this.currentApprovalAmount) * minimumAmountFactor;
  }

  /**
   * Returns 40% of current approval amount
   * @return {Float}
  */
  get maxOptionalPaymentAmount(){
    return parseFloat(this.currentApprovalAmount) * maximumAmountFactor;
  }
}
