import {Controller} from "stimulus";

// Controller for mobile application link and online application

export default class extends Controller {
  static targets = [
    "phoneNumber",
    "sendLinkButton",
    "box",
    "error",
  ];

  connect() {
  }

  get phoneNumber() {
    return this.phoneNumberTarget;
  }

  get sendLinkButton() {
    return this.sendLinkButtonTarget;
  }

  get validateUrl() {
    return this.boxTarget.dataset.validateUrl;
  }

  get submitUrl() {
    return this.boxTarget.dataset.submitUrl;
  }

  get webLinkApplicationUrl() {
    return this.boxTarget.dataset.webLinkApplicationUrl;
  }

  get linkApplicationUrl() {
    return this.boxTarget.dataset.linkApplicationUrl;
  }

  get kipperFlagEnabled() {
    return this.boxTarget.dataset.kipperFlagEnabled;
  }

  get invalidNumber() {
    return this.boxTarget.dataset.invalidNumber;
  }

  clearErrors() {
    this.errorTarget.textContent = "";
    this.errorTarget.parentElement.classList.remove("has-error");
  }

  showErrors(errorMessage) {
    this.errorTarget.textContent = errorMessage;
    this.errorTarget.parentElement.classList.add("has-error");
  }

  phoneNumberChange(event) {
    this.clearErrors();

    let number = this.phoneNumber.value;
    number = this.first10Digits(number);
    number = this.withDashes(number);
    this.phoneNumber.value = number;

    if (number.length === 12) {
      this.validateNumber(number);
    } else {
      this.sendLinkButton.disabled = true;
    }
  }

  first10Digits(number) {
    return number.replace(/\D/g, "").slice(0, 10);
  }

  withDashes(number) {
    const first = number.slice(0, 3);
    const middle = number.slice(3, 6);
    const last = number.slice(6);

    return [first, middle, last].filter((segment) => segment.length > 0).join("-");
  }

  /**
   * Action for validating the mobile phone number
   */

  validateNumber(number) {
    const fetchInit = window.acima.fetchInit({
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const validateUrl = new URL(this.validateUrl);
    const params = new URLSearchParams(validateUrl.search);

    params.append("mobile_number", number);
    validateUrl.search = params;
    if (!validateUrl.pathname.endsWith(".json")) {
      validateUrl.pathname += ".json";
    }
    fetch(validateUrl.toString(), fetchInit).then(
      response => {
        return response.json();
      }).then(data => {
      if (data) {
        if (data.type == "mobile" || data.type == "voip") {
          this.sendLinkButton.disabled = false;
        } else {
          this.showErrors(this.invalidNumber);
        }
      }
    });
  }

  /*
   * Action for submitting form
   */
  submitForm() {
    var selectedLocation = document.getElementById("location_id");
    if (selectedLocation.length >= 2) {
      // eslint-disable-next-line max-len
      var selectedOption = selectedLocation.options[selectedLocation.selectedIndex];
      // eslint-disable-next-line max-len
    } else {
      var selectedOption = selectedLocation;
    }
    const submitUrl = this.webLinkApplicationUrl;
    const locationId = selectedLocation.value;
    const phoneNumber = this.phoneNumber.value;
    const params = JSON.stringify({
      location_id: locationId,
      mobile_number: phoneNumber
    });
    const fetchWith = window.acima.fetchInit({ method: "POST", body: params });

    fetch(submitUrl, fetchWith).then(response => {
      if (response.ok) {
        document.location.reload();
      } else {
        return response.json();
      }
    }).then(response => {
      if (response.message) {
        this.showErrors(response.message);
      }
    });
  }
};
