import { Controller } from "stimulus";
import {
  showSpinnerDialog
} from "../../shared/common/dialogs/spinner_dialog_controller";
import { setTaxAmount } from "../../helpers/foreign_taxes_calculator_helper";

const optionalPaymentElectionValue = "optional_payment_election";
const maximumAmountFactor = 0.4;
const minimumAmountFactor = 0.1;

export default class extends Controller {
  static targets = [
    "acimaLeaseAmount",
    "damagedCheckbox",
    "damagesDescription",
    "damagesDescriptionContainer",
    "damagesDescriptionStatus",
    "feeDivider",
    "goodsAndServicesTax",
    "goodsAndServicesTaxValue",
    "harmonizedSalesTax",
    "harmonizedSalesTaxValue",
    "provincialSalesTax",
    "provincialSalesTaxValue",
    "puertoRicanSalesTax",
    "initialPaymentAmount",
    "initialPaymentAmountWithTax",
    "invoiceForm",
    "itemizedFeeContainer",
    "leaseDetailsPreview",
    "leaseDetailsLink",
    "leaseTermsContainer",
    "markup",
    "merchandiseConditionNew",
    "merchandiseConditionStatus",
    "merchandiseConditionUsed",
    "merchandiseTotal",
    "merchandiseTotalInput",
    "merchandiseTotalInputError",
    "merchandiseTotalStatus",
    "merchantFeeAmount",
    "merchantFeePercentage",
    "merchantMessage",
    "merchantReferenceNumber",
    "merchantReferenceNumberStatus",
    "programId",
    "programIdContainer",
    "promisedDeliveryDate",
    "promisedDeliveryDateStatus",
    "propertyDescription",
    "propertyDescriptionStatus",
    "processingFee",
    "preRentPurchaseTerm",
    "preRentBuyoutAmount",
    "remainingBuyoutAmountLabel",
    "remainingPreRentBuyoutAmount",
    "renewalPaymentAmount",
    "renewalPaymentFrequency",
    "rentPayment",
    "spiffEmail",
    "spiffEmailInvalid",
    "spiffEmailStatus",
    "submitButton",
    "termLengthContainer",
    "totalOfPayments",
    "totalOfRenewalPayments",
    "underwritingProgramInitialPayment",
    "underwritingProgramId",
    "prepaidRentButton",
    "leaseTermsContainerOptions",
    "optionalPaymentAmount",
    "optionalPaymentSelectedPaymentType",
    "optionalPaymentsSection",
    "invalidOptionalPaymentLabel",
    "optionalPaymentEditBox",
    "LdwCheckbox",
    "optionalServicesLdwFeeAmount",
    "BenefitsPlusCheckbox",
    "optionalServicesBenefitsPlusFeeAmount",
    "opeEnabled",
    "selectedRenewalPaymentAmount",
    "additionalPaymentButtons",
  ];

  connect() {
    this.loadInvoice();
    this.previousRetailerInvoiceTotal = 0.0;
    // this flag allows to force the optional payment confirmation after a certain workflow.
    this.requireOptionalPaymentConfirmation = false;
  }

  loadInvoice() {
    this.merchandiseTotalInputTarget.value = accounting.toFixed(this.merchandiseTotalInputTarget.value, 2);

   if (
     this.hasGoodsAndServicesTaxTarget &&
     this.hasGoodsAndServicesTaxValueTarget
   ) {
     this.goodsAndServicesTaxValueTarget.textContent = accounting.formatMoney(
       this.goodsAndServicesTaxTarget.value,
       "$",
       2
     );
   }
   if (
     this.hasProvincialSalesTaxTarget &&
     this.hasProvincialSalesTaxValueTarget
   ) {
     this.provincialSalesTaxValueTarget.textContent = accounting.formatMoney(
       this.provincialSalesTaxTarget.value,
       "$",
       2
     );
   }
   if (
     this.hasHarmonizedSalesTaxTarget &&
     this.hasHarmonizedSalesTaxValueTarget
   ) {
     this.harmonizedSalesTaxValueTarget.textContent = accounting.formatMoney(
       this.harmonizedSalesTaxTarget.value,
       "$",
       2
     );
   }

    this.selectOnlyProgramAvailable();
    if (this.data.get("invoiceMode") == "edit") this.validateAll();
    if (this.invoiceFormReady) {
      this.calculateInvoiceAmounts();
      this.leaseDetailsLinkTarget.classList.remove("disabled");
      this.submitButtonTarget.classList.remove("disabled");
      this.submitButtonTarget.disabled = false;
    } else if (this.merchandiseTotalReady) {
      this.calculateInvoiceAmounts();
    }
  }

  invoiceCalculationsRequestPath() {
    const requestUrl = new URL(new URL(window.location).origin);
    const merchandiseTotal = accounting.unformat(this.merchandiseTotalInputTarget.value, "", 2);
    const path = `/users/contracts/${this.data.get("leaseId")}/invoice_calculations/new`;
    const searchParams = new URLSearchParams([
      ["merchandise_total", merchandiseTotal],
      ["underwriting_program_ids", this.findProgramIdSelected()],
      ["lang", window.acima.i18n._locale],
      ["total_foreign_taxes", this.totalTaxAmounts],
    ]);

    requestUrl.pathname = path;
    requestUrl.search = searchParams;

    return requestUrl.pathname + requestUrl.search;
  }

  fetchInvoiceCalculations = async () => {
    try {
      const fetchWith = window.acima.fetchInit({ method: "GET" });
      const response = await fetch(this.invoiceCalculationsRequestPath(), fetchWith);

      return response.json();
    } catch (error) {
      console.error("Error fetching invoice calculations:", error);
    }
  }

  updateUI = (optionalService, data) => {
    if (!this["hasOptionalServices" + optionalService + "FeeAmountTarget"]) return;

    const details = data.details[0];
    const feeAmountTarget = this["optionalServices" + optionalService + "FeeAmountTarget"];
    const toSnake = string => {
      return string.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');
    };

    if (details === null || details === undefined) return;

    if (this[optionalService + "CheckboxTarget"].checked) {
      feeAmountTarget.textContent = accounting.formatMoney(details[toSnake(optionalService) + "_fee_amount"], "$", 2);
    } else {
      feeAmountTarget.textContent = "Opted out";
    }
  }

  fetchLdwFeeAmount() {
    this.fetchInvoiceCalculations().then(data => this.updateUI("Ldw", data))
  };

  toggleLdwFeeAmountCalculation() {
    this.invoiceLeaseTermsController.fetchAndCalculateAmounts;
  }

  fetchBenefitsPlusFeeAmount() {
    this.fetchInvoiceCalculations().then(data => this.updateUI("BenefitsPlus", data))
  }

  toggleBenefitsPlusFeeAmountCalculation() {
    this.invoiceLeaseTermsController.fetchAndCalculateAmounts;
  }

  calculateInvoiceAmounts() {
    this.fetchLdwFeeAmount();
    this.fetchBenefitsPlusFeeAmount();

    if (this.isMerchandiseTotalEmptyForForeignTax) return;
    if (this.hasLeaseTermsContainerTarget) return;
    if (!this.programIdReady) return;

    const setFormattedText = (targets, value, isSingleTarget = false) => {
      const formattedValue = accounting.formatMoney(value, "$", 2);

      if (isSingleTarget) {
        targets.textContent = formattedValue;
      } else {
        targets.forEach(elem => { elem.textContent = formattedValue; });
      }
    };

    const controller = this;

    const populateUI = (data) => {
      const details = data.details[0];
      const currentAcimaLeaseAmount = controller.calculateCurrentAcimaLeaseAmount(details.acima_lease_amount);
      if(controller.hasProvincialSalesTaxTarget){
        setFormattedText(controller.provincialSalesTaxTarget, details.provincial_sales_tax , true)
      }

      setFormattedText(controller.acimaLeaseAmountTargets, currentAcimaLeaseAmount);
      setFormattedText(controller.markupTargets, details.markup);
      setFormattedText(controller.initialPaymentAmountTargets, details.initial_payment_amount);

      controller.initialPaymentAmountTargets.forEach(elem => { elem.dataset.content = details.initial_payment_amount });
      setFormattedText(controller.initialPaymentAmountWithTaxTarget, details.initial_payment_amount_with_tax, true);
      setFormattedText(controller.merchandiseTotalTargets, details.merchandise_total);

      if (controller.hasPreRentBuyoutAmountTarget) {
        controller.preRentPurchaseTermTarget.textContent =
          acima.i18n.t(
            "invoices.calculations.lease_details_preview.buyout_amount",
            { term: details.pre_rent_buyout_days }
          );
        setFormattedText(controller.preRentBuyoutAmountTarget, details.ninety_day_buyout_amount, true);
      }

      setFormattedText(controller.processingFeeTarget, details.processing_fee, true);

      if (controller.hasRemainingPreRentBuyoutAmountTarget) {
        controller.remainingBuyoutAmountLabelTarget.textContent =
          acima.i18n.t(
            "invoices.calculations.lease_details_preview.remaining_buyout_amount",
            { term: details.pre_rent_buyout_days }
          );
        setFormattedText(controller.hasRemainingPreRentBuyoutAmountTarget, details.remaining_ninety_day_buyout_amount, true);
      }

      setFormattedText(controller.renewalPaymentAmountTarget, details.renewal_payment_amount, true);
      controller.renewalPaymentFrequencyTarget.textContent = details.renewal_payment_frequency;
      setFormattedText(controller.rentPaymentTarget, details.initial_rent_payment, true);
      setFormattedText(controller.totalOfPaymentsTarget, details.total_of_payments, true);
      setFormattedText(controller.totalOfRenewalPaymentsTarget, details.total_of_renewal_payments, true);

      if (controller.hasProgramIdContainerTarget) {
        controller.itemizedFeeContainerTargets.forEach(element => element.classList.remove("selected-fee"));

        if (controller.itemizedFeeContainerTargets.length === 1) {
          controller.displayItemizedFeeValue(details, controller.itemizedFeeContainerTarget);
        } else {
          controller.itemizedFeeContainerTargets.forEach( (element) => {
            controller.displayItemizedFeeValue(details, element);
          });
        }

        controller.feeDividerTarget.classList.remove("hidden");
        controller.merchantFeePercentageTarget.textContent = "Total Fee Amount:";
        setFormattedText(controller.merchantFeeAmountTarget, details.merchant_fee_amount, true);
      }
    };

    this.fetchInvoiceCalculations().then(populateUI);
  }

  displayItemizedFeeValue(details, element) {
    if(parseInt(element.dataset.programId) === details.underwriting_program_id) {
      var children = element.children;
      for (var i = 0; i < children.length; i++) {
        var child = children[i];
        if (child.classList.contains("itemized-fee-value")) {
          child.textContent = accounting.formatMoney(details.merchandise_total * parseFloat(child.dataset.percentage));
        }
      }
      element.classList.add("selected-fee");
    }
  }

  cancelEdit() {
    window.location = window.location.pathname;
  }

  damagedChecked() {
    if (this.merchandiseDamaged) {
      this.damagesDescriptionContainerTarget.style.display = "block";
      this.damagesDescriptionTarget.disabled = false;
      this.damagesDescriptionStatusTarget.classList.remove("has-success", "has-error");
    } else {
      this.damagesDescriptionContainerTarget.style.display = "none";
      this.damagesDescriptionTarget.value = null;
      this.damagesDescriptionTarget.disabled = true;
    }
  }

  merchandiseTotalBlur() {
    this.validateMerchandiseTotal();
    this.merchantMessageTarget.classList.add("hidden");
    this.merchandiseTotalInputTarget.value = accounting.toFixed(this.merchandiseTotalInputTarget.value, 2);
    if (this.merchandiseTotalReady && this.programIdReady) {
      this.calculateInvoiceAmounts();
      this.validateAll();
    } else {
      this.acimaLeaseAmountTargets.forEach( (elem) => { elem.textContent = "" } );
      this.initialPaymentAmountWithTaxTargets.forEach( (elem) => { elem.textContent = ""} );
      this.merchandiseTotalTargets.forEach( (elem) => { elem.textContent = "" } );
    }
  }

  merchandiseTotalFocus(){
    this.showMerchantMessage()
    this.clearZeros(this.merchandiseTotalInputTarget)
  }

  showMerchantMessage() {
    if (this.merchantMessageTarget.querySelector(".helper-popup").textContent.trim()) {
      this.merchantMessageTarget.classList.remove("hidden");
    }
  }

  clearZeros(target){
    if(parseFloat(target.value) === 0){
      target.value = '';
    }
  }

  submitInvoice(e) {
    e.preventDefault();
    const additionalPaymentsController = this.additionalPaymentsController;

    if (additionalPaymentsController !== null) {
      const optionalPaymentsModalVisible = !additionalPaymentsController.additionalPaymentsFormTarget.classList.contains("hidden");

      if (optionalPaymentsModalVisible) {
        additionalPaymentsController.confirmBtnTarget.click();
        return;
      }
    }

    if (this.invoiceFormReady) {
      const message = this.data.get("agreementGeneratingText");
      if (this.data.get("invoiceMode") == "edit") {
        window.spinnerDialog = function() { showSpinnerDialog(message) };
        window.EditInvoiceConfirmationModal.show();
      } else {
        this.invoiceFormTarget.submit();
        showSpinnerDialog(message);
      }
    }
  }

  toggleLeaseDetailsPreview(e) {
    e.preventDefault();
    if (!this.leaseDetailsLinkTarget.classList.contains("disabled")) {
      this.leaseDetailsPreviewTarget.classList.toggle("hidden");
    }
  }

  updateInvoiceFormStatus() {
    if (this.invoiceFormReady) {
      this.leaseDetailsLinkTarget.classList.remove("disabled");
      this.submitButtonTarget.classList.remove("disabled");
      this.submitButtonTarget.disabled = false;
    } else if (this.programIdReady && this.merchandiseTotalReady && !this.invoiceFormReady) {
      this.leaseDetailsLinkTarget.classList.remove("disabled");
      this.submitButtonTarget.classList.add("disabled");
      this.submitButtonTarget.disabled = true;
    } else {
      this.leaseDetailsPreviewTarget.classList.add("hidden");
      this.leaseDetailsLinkTarget.classList.add("disabled");
      this.submitButtonTarget.classList.add("disabled");
      this.submitButtonTarget.disabled = true;
    }
  }

  validateAll() {
    this.validateDamagesDescription();
    this.validateMerchandiseCondition();
    this.validateMerchandiseTotal();
    this.validateMerchantReferenceNumber();
    this.validatePromisedDeliveryDate();
    this.validatePropertyDescription();
    this.validateSpiffEmail();
    this.validateProgramSelection();
    this.validateForeignTaxesReady();
  }

  validateDamagesDescription() {
    if (this.merchandiseConditionDamagesReady) {
      this.addSuccess(this.damagesDescriptionStatusTarget);
    } else {
      this.addError(this.damagesDescriptionStatusTarget);
    }
  }

  validateMerchandiseCondition() {
    if (this.merchandiseConditionReady) {
      this.addSuccess(this.merchandiseConditionStatusTarget);
    } else {
      this.addError(this.merchandiseConditionStatusTarget);
    }
  }

  validateMerchandiseTotal(forceMerchandiseTotalValidationWithOPE = false) {
    const tooLowTranslation = this.leaseDetailsLinkTarget.dataset.tooLow;
    const tooHighTranslation = this.leaseDetailsLinkTarget.dataset.tooHigh;

    if (forceMerchandiseTotalValidationWithOPE){
      if (this.hasOpeEnabledTarget && this.invoiceTotalInRange) {
        let errorMessage = window.acima.i18n.t("invoices.validations.confirm_suggested_ope_amount", { amount: `${accounting.formatMoney(this.maxApprovalAmountWithoutOPE, "$", 2)}` });
        this.merchandiseTotalInputErrorTarget.textContent = errorMessage;
        this.addError(this.merchandiseTotalStatusTarget);
      }
      return;
    }

    if (this.merchandiseTotalReady) {
      this.addSuccess(this.merchandiseTotalStatusTarget);
      this.merchandiseTotalInputErrorTarget.textContent = "";
    } else {
      const merchandiseTotalInputValue = parseFloat(this.merchandiseTotalInputTarget.value);
      let errorMessage;
      let currentValue = merchandiseTotalInputValue;

      if (this.validateTaxValue("provincialSalesTax")) {
        currentValue += parseFloat(this.provincialSalesTaxTarget.value);
      }
      if (this.validateTaxValue("goodsAndServicesTax")) {
        currentValue += parseFloat(this.goodsAndServicesTaxTarget.value);
      }
      if (this.validateTaxValue("harmonizedSalesTax")) {
        currentValue += parseFloat(this.harmonizedSalesTaxTarget.value);
      }

      if (currentValue < 100) {
        errorMessage = tooLowTranslation;
      } else if (currentValue > this.maximumMerchandiseTotal) {
        errorMessage = tooHighTranslation;
      }

      this.merchandiseTotalInputErrorTarget.textContent = errorMessage;
      this.addError(this.merchandiseTotalStatusTarget);
    }
  }

  validateMerchantReferenceNumber() {
    if (this.merchantReferenceNumberReady) {
      if (this.hasMerchantReferenceNumberTarget) {
        this.addSuccess(this.merchantReferenceNumberStatusTarget);
      }
    } else {
      this.addError(this.merchantReferenceNumberStatusTarget);
    }
  }

  validatePromisedDeliveryDate() {
    if (this.promisedDeliveryDateReady) {
      this.addSuccess(this.promisedDeliveryDateStatusTarget);
    } else {
      this.addError(this.promisedDeliveryDateStatusTarget);
    }
  }

  validatePropertyDescription() {
    if (this.propertyDescriptionReady) {
      this.addSuccess(this.propertyDescriptionStatusTarget);
    } else {
      this.addError(this.propertyDescriptionStatusTarget);
    }
  }

  validateSpiffEmail() {
    // this is an action as well as being called within the class
    if (this.spiffEmailReady) {
      if (this.hasSpiffEmailTarget) {
        this.spiffEmailInvalidTarget.classList.add("hidden");
        this.addSuccess(this.spiffEmailStatusTarget);
      }
    } else {
      this.spiffEmailInvalidTarget.classList.remove("hidden");
      this.addError(this.spiffEmailStatusTarget);
    }
  }

  validateProgramSelection() {
    if (this.hasProgramIdContainerTarget) {
      if (this.programIdReady) {
        this.addSuccess(this.programIdContainerTarget);
      } else {
        this.addError(this.programIdContainerTarget);
      }
    }
  }

  validateForeignTaxesReady() {
    if (!this.hasProvincialSalesTaxTarget) return;

    this.foreignTaxesReady;
  }

  /** Removes invalid optional payments label and runs all validations to enable submit button if needed */
  validateOptionalPaymentsForm() {
    if (!this.hasOptionalPaymentsSectionTarget) return;

    this.invalidOptionalPaymentLabelTarget.classList.add("hidden");
    this.requireOptionalPaymentConfirmation = false;
    this.updateInvoiceFormStatus();
  }

  validateTaxValue(taxType) {
    const capitalizedTaxType = taxType.charAt(0).toUpperCase() + taxType.slice(1);

    const hasFieldProperty = `has${capitalizedTaxType}Target`;
    const targetProperty = `${taxType}Target`;

    if (this[hasFieldProperty]) {
      const target = this[targetProperty];
      const taxValue = target.value;
      return taxValue !== "" && taxValue >= 0;
    }
    return false;
  }

  /** Displays invalid optional payments label if optional payment form is filled in and
    * runs all validations to disable submit button if needed */
  invalidateOptionalPaymentsFormIfCompleted() {
    if (!this.hasOptionalPaymentsSectionTarget) return;

    const completedAndPrepaidRentSelected = this.optionalPaymentCompleted &&
      this.additionalPaymentsController.isSelectedPaymentTypePrepaidRent &&
      this.requireOptionalPaymentConfirmation;
    const completedAndOptionalPaymentElectionSelected = this.optionalPaymentCompleted &&
      this.isOptionalPaymentElectionSelected;

    if (completedAndPrepaidRentSelected) {
      this.invalidOptionalPaymentLabelTarget.classList.remove("hidden");
    } else if (completedAndOptionalPaymentElectionSelected) {
      if (!this.invoiceTotalInRange) {
        this.optionalPaymentAmountTarget.value = "";
        this.optionalPaymentSelectedPaymentTypeTarget.value = "";
        this.invalidOptionalPaymentLabelTarget.classList.add("hidden");
        this.additionalPaymentButtonsTarget.classList.remove("hidden");
        this.optionalPaymentEditBoxTarget.classList.add("hidden");
        this.validateMerchandiseTotal(true);
      }
      this.setsCurrentOpeAction("optOut");
    }
    this.updateInvoiceFormStatus();
  }

  selectOnlyProgramAvailable() {
    var programTargets = this.programIdTargets.map(id => [!id.disabled, id]);
    var eligiblePrograms = [];

    if(programTargets.length == 1) {
      return;
    }

    for(var i = 0; i < programTargets.length; i++) {
      if(programTargets[i][0] == true){
        eligiblePrograms.push(programTargets[i][1]);
      }
    }

    if(eligiblePrograms.length == 1){
      eligiblePrograms[0].checked = true;
      eligiblePrograms[0].closest('label').classList.add('selected-program')
      this.validateProgramSelection();
    }
  }

  programSelect(event) {
    // this is an action
    this.validateProgramSelection();
    this.validateAll();

    var other_programs = document.getElementsByClassName("selected-program");

    if(other_programs != undefined) {
      while (other_programs.length) other_programs[0].classList.remove("selected-program");
    }

    event.currentTarget.closest('label').classList.add('selected-program')
    if (this.programIdReady && this.merchandiseTotalReady) {
      this.calculateInvoiceAmounts();
    }
  }

  findProgramIdSelected() {
    if (this.hasProgramIdTarget) {
      var program_options = this.programIdTargets.map(id=>[id.checked, id.value])

      for(var i = 0; i < program_options.length; i++) {
        if (program_options[i].indexOf(true) > -1) {
          return String(program_options[i][1]);
        }
      }
    } else if (this.selectedTerm !== null && this.selectedTerm !== undefined) {
      return this.selectedTerm.dataset.id;
    } else {
      return null
    }
  }

  get totalTaxAmounts() {
    let taxAmounts = 0
    if (this.hasProvincialSalesTaxTarget){
      taxAmounts+= accounting.unformat(this.provincialSalesTaxTarget.value, "", 2);
    }
    if (this.hasGoodsAndServicesTaxTarget){
      taxAmounts+= accounting.unformat(this.goodsAndServicesTaxTarget.value, "", 2);
    }
   if (this.hasHarmonizedSalesTaxTarget){
      taxAmounts+= accounting.unformat(this.harmonizedSalesTaxTarget.value, "", 2);
    }

    return taxAmounts
  }

  addSuccess(target) {
    target.classList.remove("has-error");
    target.classList.add("has-success");
  }

  addError(target) {
    target.classList.remove("has-success");
    target.classList.add("has-error");
  }

  /** Enable/disable Prepaid Rent button depending on conditions */
  enablePrepaidRentButton(){
    this.fetchLdwFeeAmount();
    this.fetchBenefitsPlusFeeAmount();
    if (!this.hasPrepaidRentButtonTarget) return;

    if (this.merchandiseTotalReady && this.selectedTerm != null) {
      this.prepaidRentButtonTarget.classList.remove("disabled");
    } else {
      this.prepaidRentButtonTarget.classList.add("disabled");
    }
  }

  calculateCurrentAcimaLeaseAmount(amount) {
    let salesTaxValue = 0;

    if (this.validateTaxValue("provincialSalesTax")) {
      salesTaxValue += parseFloat(this.provincialSalesTaxTarget.value);
    }
    if (this.validateTaxValue("goodsAndServicesTax")) {
      salesTaxValue += parseFloat(this.goodsAndServicesTaxTarget.value);
    }
    if (this.validateTaxValue("harmonizedSalesTax")) {
      salesTaxValue += parseFloat(this.harmonizedSalesTaxTarget.value);
    }
    return parseFloat(amount) + salesTaxValue;
  }

  selectProvincialSalesTax(event) {
    this.provincialSalesTaxValueTarget.textContent = accounting.formatMoney(event.target.value, "$", 2);
    if (this.isMerchandiseTotalEmptyForForeignTax) return;

    this.merchandiseTotalInputTarget.focus();
    this.merchandiseTotalInputTarget.blur();
  }

  selectGoodsAndServicesTax(event) {
    this.goodsAndServicesTaxValueTarget.textContent = accounting.formatMoney(
      event.target.value,
      "$",
      2
    );
    if (this.isMerchandiseTotalEmptyForForeignTax) return;

    this.merchandiseTotalInputTarget.focus();
    this.merchandiseTotalInputTarget.blur();
  }

  selectHarmonizedSalesTax(event) {
    this.harmonizedSalesTaxValueTarget.textContent = accounting.formatMoney(
      event.target.value,
      "$",
      2
    );
    if (this.isMerchandiseTotalEmptyForForeignTax) return;

    this.merchandiseTotalInputTarget.focus();
    this.merchandiseTotalInputTarget.blur();
  }

  /** Opens optional payments modal and populates it with OPE related info and the suggested OPE amount */
  triggerOptionalPaymentsModal(){
    if (this.hasOpeEnabledTarget && this.invoiceTotalInRange){
      this.opeEnabledTarget.click();
      this.optionalPaymentAmountTargets.forEach((amount) => {
        amount.textContent = accounting.formatMoney(this.suggestedOpeAmount, "$", 2);
      });
      this.additionalPaymentsController.toggleStateConfirmButton();
      this.merchandiseTotalInputErrorTarget.textContent = "";
      this.requireOptionalPaymentConfirmation = true;
    }
  }

  setsCurrentOpeAction(action){
    this.element.setAttribute("users--contracts--invoices-current-ope-action", action);
  }

  populateRenewalPaymentAmountValue(){
    if (this.merchandiseTotalReady && this.selectedTerm != null && this.hasSelectedRenewalPaymentAmountTarget) {
      this.selectedRenewalPaymentAmountTarget.value = this.selectedTerm.dataset.renewalPaymentAmount;
    }
  }

  setTaxAmountHandler(){
    setTaxAmount(this);
  }

  /**
   * Get selectedTerm (Every Other Week Recurring Payment)
   * @return {Element|null} HTML element if selected, null otherwise.
   */
  get selectedTerm(){
    return this.leaseTermsContainerOptionsTargets.filter((term) => {
      return term.classList.contains("selected-term-length");
    })[0];
  }

  get validateLeaseTermInvoice() {
    return this.application.controllers.find( (controller) => {
      if (controller.identifier.toString() == "users--contracts--invoice-lease-terms") {
        return controller.underwritingProgramIdTarget.value != "";
      } else {
        return false;
      }
    });
  }

  get invoiceFormReady() {
    return this.leaseTermsReady
      && this.merchandiseConditionDamagesReady
      && this.merchandiseConditionReady
      && this.merchandiseTotalReady
      && this.merchantReferenceNumberReady
      && this.promisedDeliveryDateReady
      && this.propertyDescriptionReady
      && this.spiffEmailReady
      && this.programIdReady
      && this.foreignTaxesReady
      && this.optionalPaymentsFormReady;
  }

  get leaseTermsReady() {
    if (this.hasLeaseTermsContainerTarget) {
      if (this.underwritingProgramIdTarget.value != "") {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  get merchandiseConditionReady() {
    return this.merchandiseConditionNewTarget.checked || this.merchandiseConditionUsedTarget.checked;
  }

  get merchandiseConditionDamagesReady() {
    if (this.merchandiseDamaged) {
      return this.damagesDescriptionTarget.value.trim().length > 0;
    } else {
      return true;
    }
  }

  get merchandiseDamaged() {
    return this.damagedCheckboxTarget.checked;
  }

  get merchandiseTotalReady() {
    const merchandiseTotalInputValue = parseFloat(this.merchandiseTotalInputTarget.value);
    let currentValue = merchandiseTotalInputValue;

    if (this.validateTaxValue("provincialSalesTax")) {
      currentValue += parseFloat(this.provincialSalesTaxTarget.value);
    }
    if (this.validateTaxValue("goodsAndServicesTax")) {
      currentValue += parseFloat(this.goodsAndServicesTaxTarget.value);
    }
    if (this.validateTaxValue("harmonizedSalesTax")) {
      currentValue += parseFloat(this.harmonizedSalesTaxTarget.value);
    }

    return currentValue >= 100 && currentValue <= this.maximumMerchandiseTotal;
  }

  get merchantReferenceNumberReady() {
    if (this.hasMerchantReferenceNumberTarget) {
      return this.merchantReferenceNumberTarget.value.trim().length > 0;
    } else {
      return true;
    }
  }

  get promisedDeliveryDateReady() {
    var dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    return dateRegex.test(this.promisedDeliveryDateTarget.value);
  }

  get propertyDescriptionReady() {
    return this.propertyDescriptionTarget.value.trim().length > 0 &&
           this.propertyDescriptionTarget.value.trim().length <= 1500;
  }

  get spiffEmailReady() {
    if (this.hasSpiffEmailTarget) {
      var emailRegex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
      return emailRegex.test(this.spiffEmailTarget.value);
    } else {
      return true;
    }
  }

  get programIdReady() {
    if (this.hasProgramIdTarget) {
      var programs_checked = this.programIdTargets.map(id => id.checked)
      if (programs_checked.indexOf(true) > -1) {
        return true;
      } else {
        return false;
      }
    } else if (this.hasLeaseTermsContainerTarget) {
      return this.validateLeaseTermInvoice;
    } else {
      return true;
    }
  }

  /**
   * Returns lease-terms-controller reference
   * @return {Object} lease-terms-controller reference.
   */
  get invoiceLeaseTermsController() {
    const selector = document.querySelector("[data-controller='users--contracts--invoice-lease-terms']");
    const controllerName = "users--contracts--invoice-lease-terms";

    return this.application.getControllerForElementAndIdentifier(selector, controllerName);
  }

  /**
   * Returns foreign-taxes-controller reference
   * @return {Object} foreign-taxes-controller reference
   */
  get foreignTaxesController() {
    const selector = document.querySelector("#inv_form");
    const controllerName = "foreign-taxes";

    return this.application.getControllerForElementAndIdentifier(selector, controllerName);
  }

  get foreignTaxesReady() {
    if (!this.anyForeignTaxPresent) return true;
    if (!this.foreignTaxesController) return true;

    return this.anyForeignTaxPresent
      && this.foreignTaxesController.positiveNumberReady
      && this.foreignTaxesController.requiredFieldsReady
      && this.merchandiseTotalReady;
  }

  anyForeignTaxPresent() {
    return this.hasProvincialSalesTaxTarget
      || this.hasHarmonizedSalesTaxTarget
      || this.hasGoodsAndServicesTaxTarget
      || this.hasPuertoRicanSalesTaxTarget;
  }

  // Skip calculation when merchandiseTotal is 0, leaseAmount is empty and event is fired from tax fields.
  get isMerchandiseTotalEmptyForForeignTax() {
    const merchandiseTotalIsZero = parseFloat(this.merchandiseTotalInputTarget.value) === 0.00;
    const leaseAmountIsEmpty = this.acimaLeaseAmountTarget.textContent === '';

    return (
      (this.emptyProvincialSalesTax ||
        this.emptyGoodsAndServicesTax ||
        this.emptyHarmonizedSalesTax) &&
      merchandiseTotalIsZero &&
      leaseAmountIsEmpty
    );
  }

  get emptyProvincialSalesTax(){
    return (
      this.hasProvincialSalesTaxTarget &&
      parseFloat(this.provincialSalesTaxTarget.value) === 0.0
    );
  }

  get emptyGoodsAndServicesTax(){
    return (
      this.hasGoodsAndServicesTaxTarget &&
      parseFloat(this.goodsAndServicesTaxTarget.value) === 0.0
    );
  }

  get emptyHarmonizedSalesTax(){
    return (
      this.hasHarmonizedSalesTaxTarget &&
      parseFloat(this.harmonizedSalesTaxTarget.value) === 0.0
    );
  }

  /**
   * Returns false if the editBox for optional payments is visible in the page
   * and also the error message that prompts the user to confirm the amount is visible in the page
   * this means the user has already selected an optional payment and the amount might be invalid
   * Returns true by default if the optional Payments section is not visible in the page
   * @return {Boolean}
   */
  get optionalPaymentsFormReady() {
    if (this.hasOptionalPaymentsSectionTarget) {
      const editBoxVisible = !this.optionalPaymentEditBoxTarget.classList.contains("hidden");
      const invalidMessageVisible = !this.invalidOptionalPaymentLabelTarget.classList.contains("hidden");

      if (editBoxVisible && invalidMessageVisible) return false;
    }
    return true;
  }

  /**
   * Returns true if optional payment form is filled in
   * @return {Boolean}
   */
  get optionalPaymentCompleted() {
    return this.optionalPaymentAmountTarget.value.trim().length > 0 &&
      this.optionalPaymentSelectedPaymentTypeTarget.value.trim().length > 0
  }

  /**
  * Returns true if optional payment election is selected, false otherwise
  * @return {Boolean}
  */
  get isOptionalPaymentElectionSelected(){
    return this.optionalPaymentSelectedPaymentTypeTarget.value == optionalPaymentElectionValue;
  }

  /**
   * Returns true if element is present and checked, otherwise false
   * @returns {boolean}
   */
  get isLdwCheckboxChecked() {
    if (!this.hasLdwCheckboxTarget) return false;

    return !!this.LdwCheckboxTarget.checked;
  }

  /**
   * Returns true if element is present and checked, otherwise false
   * @returns {boolean}
   */
  get isBenefitsPlusCheckboxChecked() {
    if (!this.hasBenefitsPlusCheckboxTarget) return false;

    return !!this.BenefitsPlusCheckboxTarget.checked;
  }

  /**
   * Returns 40% of current approval amount
   * @return {Float}
  */
  get maxOptionalPaymentAmount(){
    return parseFloat(this.currentApprovalAmount) * maximumAmountFactor;
  }

  /**
   * Returns current approval amount
   * @return {Float}
  */
  get currentApprovalAmount(){
    return parseFloat(this.element.getAttribute("data-users--contracts--invoices-approval-amount"));
  }

  /**
   * Returns max approval amount which is 140% of the current approval amount
   * @return {Float}
  */
  get maxApprovalAmount(){
    return this.currentApprovalAmount + this.maxOptionalPaymentAmount;
  }

  /**
   * If optional payment election is enabled, the the max invoice_total can exceed the approval amount up to 40% (140%)
   * else can exceed the approval amount up to 10% as usual (110%)
   * @return {Float}
  */
  get maximumMerchandiseTotal(){
    const maxMerchandiseTotal = parseFloat(this.data.get("maximumMerchandiseTotal"));

    return this.hasOpeEnabledTarget && this.isOptionalPaymentElectionSelectedAndFirstTimeRender ? this.maxApprovalAmount : maxMerchandiseTotal;
  }

  /**
   * Returns if OPE is selected or is the first time the coworker is rendering the page and has not interact with optional payments at all
   * @return {boolean}
  */
  get isOptionalPaymentElectionSelectedAndFirstTimeRender(){
    const currentOpeAction = this.element.getAttribute("users--contracts--invoices-current-ope-action");

    return this.isOptionalPaymentElectionSelected || currentOpeAction == null
  }

  /**
   * Returns the difference between the InvoiceTotal and the current approval amount
   * which is going to be the suggested OPE amount
   * @return {Float}
  */
  get suggestedOpeAmount(){
    const merchandiseTotal = accounting.unformat(this.merchandiseTotalInputTarget.value, "", 2);
    const currentApprovalAmount = this.currentApprovalAmount;

    return  merchandiseTotal - currentApprovalAmount;
  }

  /**
   * Returns additional-payments-controller reference
   * @return {Object} additional-payments-controller reference.
  */
  get additionalPaymentsController() {
    const selector = document.querySelector("[data-controller='users--contracts--additional-payments']");
    const controllerName = "users--contracts--additional-payments";

    return this.application.getControllerForElementAndIdentifier(selector, controllerName);
  }

  /**
   * Returns if invoice total is between the range for suggested OPE amounts
   * @return {boolean}
  */
  get invoiceTotalInRange(){
    const minOptionalPaymentAmount = this.currentApprovalAmount + this.additionalPaymentsController.minOptionalPaymentAmount;
    const maxOptionalPaymentAmount = this.currentApprovalAmount + this.additionalPaymentsController.maxOptionalPaymentAmount;
    const merchandiseTotal = accounting.unformat(this.merchandiseTotalInputTarget.value, "", 2);

    return merchandiseTotal > minOptionalPaymentAmount && merchandiseTotal <= maxOptionalPaymentAmount;
  }

  /**
   * Returns the maximum approval amount without OPE
   * @return {Float}
  */
  get maxApprovalAmountWithoutOPE(){
    return parseFloat(this.currentApprovalAmount + this.currentApprovalAmount * minimumAmountFactor);
  }
}
