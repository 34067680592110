import { Controller } from "stimulus";
/**
 * Handles Plaid integration for funding accounts
 */
export default class extends Controller {
  connect() {
    this.countryCode = this.data.get("country-code");
    this.merchantId = this.data.get("merchant-id");
    this.linkTokenPath = this.data.get("link-token-path");
    this.bankAccountsPath = this.data.get("bank-accounts-path");
  }

  addAccount(event) {
    event.preventDefault();

    // Fetch the link token from the server
    fetch(this.linkTokenPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        country_code: this.countryCode
      })
    })
      .then(response => response.json())
      .then(data => {
        this.bank_account_guid = data.acima_internal_guid;
        this.initializePlaidHandler(data.link_token);
      })
      .catch(error => {
        console.error("Error fetching link token:", error);
        window.location.reload();
      });
  }

  initializePlaidHandler(linkToken) {
    const handler = Plaid.create({
      token: linkToken,
      onSuccess: (publicToken) => this.onPlaidSuccess(publicToken),
      onExit: (err) => this.onPlaidExit(err)
    });

    handler.open();
  }

  onPlaidSuccess(publicToken) {
    // Send the public token to the server
    fetch(this.bankAccountsPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        public_token: publicToken,
        merchant_id: this.merchantId,
        bank_account_guid: this.bank_account_guid
      })
    })
      .then(response => {
        if (response.ok) {
          window.location.reload();
        } else {
          console.error("Failed to add account via Plaid");
          window.location.reload();
        }
      })
      .catch(error => {
        console.error("Error sending public token to server:", error);
        window.location.reload();
      });
  }

  onPlaidExit(err) {
    console.error("Plaid exited:", err);
    window.location.reload();
  }
}
