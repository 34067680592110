import {Controller} from "stimulus";
import {publish, subscribe} from "../../helpers/pub_sub";
import {showConfirmationDialog} from "./confirmation_dialog_controller";
import {showPastUpwardDeadlineDialog} from "./past_deadline_dialog_controller";
import { showStartNewLeaseDialog } from "./start_new_lease_dialog_controller";
import { validForeignTaxes } from "../../helpers/foreign_taxes_helper";
import {
  resetTaxAmount,
  setTaxAmount,
  selectGoodsAndServicesTax,
  selectHarmonizedSalesTax,
  selectProvincialSalesTax,
  selectPuertoRicanSalesTax,
  calculateNewInvoiceTotal,
  isACanadianLease,
} from "../../helpers/foreign_taxes/adjustments/calculator_helper"

const SUBMIT_FORM_EVENT = "submitFormEvent";

/**
 * Publishes the submit form event
 */
export function submitForm() {
  publish(SUBMIT_FORM_EVENT);
}

export default class extends Controller {
  static targets = [
    "adjustmentForm",
    "conditionNewRadio",
    "conditionUsedRadio",
    "damagedCheckBox",
    "damagesDescriptionInput",
    "damagesDescriptionStatus",
    "errorBanner",
    "errorMessage",
    "goodsAndServicesTax",
    "harmonizedSalesTax",
    "provincialSalesTax",
    "puertoRicanSalesTax",
    "invoiceChangeAmount",
    "invoiceTotal",
    "invoiceTotalStatus",
    "newInvoiceTotal",
    "newDescriptionInput",
    "newDescriptionStatus",
    "upsy",
    "downsy",
    "submitButton",
    "newGoodsAndServicesTax",
    "newHarmonizedSalesTax",
    "newProvincialSalesTax",
    "newPuertoRicanSalesTax",
  ]

  connect() {
    subscribe(SUBMIT_FORM_EVENT, this.submitForm);
  }

  validateDescription() {
    if (this.validDescription()) {
      this.addSuccess(this.newDescriptionStatusTarget);
    } else {
      this.addError(this.newDescriptionStatusTarget);
    }
    this.checkSubmittability();
  }

  validDescription() {
    return this.newDescriptionInputTarget.value.trim().length > 0 &&
           this.newDescriptionInputTarget.value.trim().length <= 1500;
  }

  handleMerchantInvoiceTotal(event) {
    this.resetInvoiceTotal();

    if (this.validInvoiceTotal() == false) {
      this.invoiceTotalTarget.value = "";
      this.submitButtonTarget.classList.add("disabled");
      this.addError(this.invoiceTotalStatusTarget);
      this.addErrorBanner(
        acima.i18n.t("merchants.contracts.invoice_adjustments.invoice_step.doing.alerts.invalid_input"),
        this.invoiceTotalStatusTarget
      );
      return;
    }

    this.getValidations(event);
  }

  validInvoiceTotal() {
    const total = this.invoiceTotalTarget.value.replace(/[^0-9.]/g, "");

    if (!total.trim()) return false; // This will be falsey if it is an empty string
    if (isNaN(total)) return false;

    this.invoiceTotalTarget.value = accounting.toFixed(total, 2);

    return true;
  }

  getValidations(event) {
    const fetchWith = window.acima.fetchInit({method: "GET"});
    const controller = this;
    fetch(
        this.validationsPath(),
        fetchWith
    )
      .then((response) => response.json())
      .then(function(jsonResponse) {
        controller.handleValidationsResponse(jsonResponse, event);
      }).catch(function(error) {
        console.log(error.message);
      });
  }

  handleValidationsResponse(jsonResponse, event) {
    const code = jsonResponse.code;

     switch (code) {
      case "reached_deadline":
        showPastUpwardDeadlineDialog();
        this.invoiceTotalTarget.value = "";
        this.addError(this.invoiceTotalStatusTarget);
        resetTaxAmount(this);
        break;
      case "exceeds_approval_amount":
        this.invoiceTotalTarget.value = "";
        this.addError(this.invoiceTotalStatusTarget);
        resetTaxAmount(this);
        this.addErrorBanner(
          acima.i18n.t("merchants.contracts.invoice_adjustments.invoice_step.doing.alerts.approval_warning")
        );
        break;
      case "invalid_funding_method":
        showStartNewLeaseDialog();
        this.invoiceTotalTarget.value = "";
        this.addError(this.invoiceTotalStatusTarget);
        resetTaxAmount(this);
        break;
      case "amount_below_minimum":
        this.invoiceTotalTarget.value = "";
        this.addError(this.invoiceTotalStatusTarget);
        resetTaxAmount(this);
        this.addErrorBanner(
          acima.i18n.t(
            "merchants.contracts.invoice_adjustments.errors.amount_below_minimum",
            { minimum_amount: this.errorBannerTarget.dataset.minimumAmount },
          )
        );
        break;
      default:
        this.addSuccess(this.invoiceTotalStatusTarget);

        if (event.target == this.invoiceTotalTarget) {
          setTaxAmount(this);
        }

        this.determineDirectionality();

        var newInvoiceTotalValue = calculateNewInvoiceTotal(this)
        this.newInvoiceTotalTarget.innerHTML = accounting.formatMoney(newInvoiceTotalValue, "$", 2, ",");
    }
    this.checkSubmittability();
  }

  validationsPath() {
    const requestUrl = new URL(new URL(window.location).origin);
    const invoiceTotal = this.invoiceTotalTarget.value;
    const path = this.data.get("validationsUrl").split("?")[0];
    const searchParams = new URLSearchParams(
      {
        merchant_invoice_total: invoiceTotal,
        lang: window.acima.i18n._locale,
      }
    );
    requestUrl.pathname = path;
    requestUrl.search = searchParams;

    return requestUrl.pathname + requestUrl.search;
  }

  toggleDamagesDescription() {
    if (this.damagedCheckBoxTarget.checked == true) {
      this.damagesDescriptionStatusTarget.classList.remove("hidden");
      this.damagesDescriptionInputTarget.value = null;
      this.submitButtonTarget.classList.add("disabled");
    } else {
      this.damagesDescriptionStatusTarget.classList.add("hidden");
      this.damagesDescriptionStatusTarget.classList.remove("has-error", "has-success");
      this.checkSubmittability();
    }
  }

  validateDamagesDescription() {
    if (this.validDamagesDescription()) {
      this.addSuccess(this.damagesDescriptionStatusTarget);
    } else {
      this.addError(this.damagesDescriptionStatusTarget);
    }
    this.checkSubmittability();
  }

  validDamagesDescription() {
    if (this.damagedCheckBoxTarget.checked === false) return true;

    return !!this.damagesDescriptionInputTarget.value;
  }

  addSuccess(target) {
    target.classList.remove("has-error");
    target.classList.add("has-success");
  }

  addError(target) {
    target.classList.remove("has-success");
    target.classList.add("has-error");
  }

  addErrorBanner(message) {
    this.errorBannerTarget.classList.remove("hide");
    this.errorMessageTarget.innerHTML = message;
  }

  checkSubmittability() {
    if (this.submittable()) {
      this.submitButtonTarget.classList.remove("disabled");
    } else {
      this.submitButtonTarget.classList.add("disabled");
    }
  }

  submittable() {
    return this.validDamagesDescription() &&
      this.validInvoiceTotal() &&
      this.validDescription() &&
      validForeignTaxes(this);
  }

  confirmAdjustment() {
    var changeAmount = this.invoiceChangeAmountTarget.innerHTML;
    var newInvoiceTotal = this.newInvoiceTotalTarget.innerHTML;

    showConfirmationDialog(
        changeAmount, newInvoiceTotal, this.changeIsUpsy
    );
  }

  clearBreakdownValues() {
    this.upsyTarget.classList.add("hidden");
    this.downsyTarget.classList.add("hidden");
    this.invoiceChangeAmountTarget.innerHTML = "-";
    this.newInvoiceTotalTarget.innerHTML = "-";
  }

  determineDirectionality() {
    var newTotal = parseFloat(this.invoiceTotalTarget.value);
    var originalTotal = this.data.get("originalInvoiceTotal");

    if (isACanadianLease() && this.hasNewProvincialSalesTaxTarget) {
      newTotal += parseFloat(this.newProvincialSalesTaxTarget.innerHTML.replace('$', ''));
    }

    var difference = parseFloat(newTotal).toFixed(2) - parseFloat(originalTotal);

    if (difference > 0.0) {
      this.changeIsUpsy = true;
      this.upsyTarget.classList.remove("hidden");
      this.downsyTarget.classList.add("hidden");
    } else {
      this.changeIsUpsy = false;
      this.upsyTarget.classList.add("hidden");
      this.downsyTarget.classList.remove("hidden");
    }
    this.invoiceChangeAmountTarget.innerHTML = accounting.formatMoney(Math.abs(difference), "$", 2, ",");
  }

  resetInvoiceTotal() {
    this.clearBreakdownValues();
    this.errorBannerTarget.classList.add("hide");
    this.invoiceTotalStatusTarget.classList.remove("has-error");
  }

  validatePstValue() {
    if (this.hasProvincialSalesTaxTarget) {
      const pstValue = this.provincialSalesTaxTarget.value;
      return pstValue !== "" && pstValue >= 0;
    }
    return false;
  }

  submitForm = () => {
    this.adjustmentFormTarget.submit();
  }

  selectPuertoRicanSalesTaxHandler(event) {
    selectPuertoRicanSalesTax(this, event.target.value);
  }
  
  selectProvincialSalesTaxHandler(event) {
    selectProvincialSalesTax(this, event.target.value);
  }
  
  selectGoodsAndServicesTaxHandler(event) {
    selectGoodsAndServicesTax(this, event.target.value);
  }
  
  selectHarmonizedSalesTaxHandler(event) {
    selectHarmonizedSalesTax(this, event.target.value);
  }
}
