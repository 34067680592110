import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "locationsToAdd",
    "locationsToRemove",
    "locationIds",
    "findLocationsButton",
    "addLocationButton",
    "removeLocationButton",
  ]
  connect() {
    this.locationsToAdd = [];
    this.locationsToRemove = [];
  }

  /**
   * Updates the locations list with the current filter parameters
   * @param {Event} event - The event that triggered the update
   */
  updateLocationsList(event) {
    let query_str;
    const query = [];
    const addLocations = this.locationsToAddTarget.value.replaceAll(/[^\w-,]/g, "");
    const removeLocations = this.locationsToRemoveTarget.value.replaceAll(/[^\w-,]/g, "");
    const locationIds = this.locationIdsTarget.value.replaceAll(/[^\w-,]/g, "");

    if (!!addLocations) {
      query.push(`locations_to_add=${addLocations}`);
    }

    if (!!removeLocations) {
      query.push(`locations_to_remove=${removeLocations}`);
    }

    if (!!locationIds) {
      query.push(`location_ids=${locationIds}`);
    }

    if (query.length > 0) {
      query_str = "?" + query.join("&");
    } else {
      query_str = "";
    }

    const locationsUrl = `${event.currentTarget.dataset.locationsUrl}/${query_str}`;
    window.location = locationsUrl;
  }

  /**
   * Adds a location to the locations to add list
   * @param {Event} event - The event that triggered the add
   */
  addLocation(event) {
    const locationId = event.currentTarget.dataset.locationId;
    event.currentTarget.style.display = "none";
    const removeButton = this.removeLocationButtonTargets.find(button => button.dataset.locationId === locationId);
    removeButton.style.display = "";

    let locationsToRemoveIndex = this.locationsToRemove.indexOf(locationId);
    if (locationsToRemoveIndex !== -1) {
      this.locationsToRemove.splice(locationsToRemoveIndex, 1);
      this.locationsToRemoveTarget.value = this.locationsToRemove;
    } else {
      this.locationsToAdd.push(locationId);
      this.locationsToAddTarget.value = this.locationsToAdd;
    }
  }

  /**
   * Removes a location from the locations to remove list
   * @param {Event} event - The event that triggered the remove
   */
  removeLocation(event) {
    const locationId = event.currentTarget.dataset.locationId;
    event.currentTarget.style.display = "none";
    const addButton = this.addLocationButtonTargets.find(button => button.dataset.locationId === locationId);
    addButton.style.display = "";

    let locationsToAddIndex = this.locationsToAdd.indexOf(locationId);
    if (locationsToAddIndex !== -1) {
      this.locationsToAdd.splice(locationsToAddIndex, 1);
      this.locationsToAddTarget.value = this.locationsToAdd;
    } else {
      this.locationsToRemove.push(locationId);
      this.locationsToRemoveTarget.value = this.locationsToRemove;
    }
  }
}
