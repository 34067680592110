import { Controller } from "stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ["field"]
  connect() {
    new Choices(this.fieldTarget, {
      searchEnabled: true,
      itemSelectText: '',
      shouldSort: false,
    });
  }
}
