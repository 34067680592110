import { Controller } from "stimulus";
import { alertHelper } from "../helpers/alert_helpers";

/**
 * Sends plaid verification SMS to applicant
 */
export default class extends Controller {
  static targets = ["mobileNotice"];

  sendSms(e) {
    const target = e.currentTarget;
    const guid = target.dataset.guid;
    const url = target.dataset.url;
    const fetchWith = window.acima.fetchInit({ method: "POST" });

    target.setAttribute("disabled", "disabled")
    e.preventDefault();

    fetch(url, fetchWith)
      .then(res => res.json())
      .then(res => this.handleResponse(res, target))
      .catch(err => this.handleError(err));
  }

  handleResponse(json) {
    if (json.success) {
      alertHelper.success(this.mobileNoticeTarget, json.message);
    } else {
      alertHelper.error(this.mobileNoticeTarget, json.message);
    }

    setTimeout(() => { window.location.reload(); }, 5000);
  }

  handleError(err) {
    alertHelper.error(this.mobileNoticeTarget, err);
    setTimeout(() => { window.location.reload(); }, 5000);
  }
}
